
.item {
    border: 1px solid #d9d9d969;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 10px;
    margin: 5px 0px !important;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}


.body {
    width: 100%;
}


.button_container{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 15px;
    padding:5px 25px;
}

@media (min-width: 1300px) {
    .button_container {
        left:auto;
        right: auto;
        bottom: 10px;
        width: 420px;
        padding: 0px 5px;

    }
    /*.footer{*/
    /*    width: 500px;*/
    /*}*/
}