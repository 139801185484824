
.container {
    padding: 15px 15px;
    /*--background-color: #fff;*/
    /*--border-color: #fff;*/
    /*border-radius: 5px;*/
    /*border: 1px solid var(--border-color);*/
    /*background-color: var(--background-color);*/
    /*border: 1px solid #aaa;*/
}

.container > h1 {
    margin-bottom: 10px;
    /* text-align: center; */
    font-size: 25px;
}

.container p {
    /*margin-bottom: 10px;*/
}

.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
    gap: 10px;
}
