.container {
    padding: 10px 0;
}

.container.preview {
    width: 100%;
}


.image_text {
    display: flex;
    gap: 7px;
    align-items: center;

}

.image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    width: auto;
}

.text p {
    opacity: 0.75;
    margin-top: 5px;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}

.items_container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    transition: .3s;
    border-radius: 8px;
    padding: 14px;
}

.drop_down_items {
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
    cursor: pointer;
    transition: .3s;
    border-radius: 11px;
    width: 100%;
    border-bottom: 1px dashed #EBEBEB;
    padding: 5px;
}

.drop_down_items_container {
    padding: 5px;
}

.item:hover {
    /* background: #f9f9f9; */
}

.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

.no_found_data {
    padding: 40px;
    text-align: center !important;
    justify-content: center;
}

.image {
    /*aspect-ratio: 1 / 1;*/
    aspect-ratio: auto;

}

.text h1 {
    margin-top: 20px;
}

.selectBtn_click {
    margin-bottom: -1px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.selectBtn_UnClick {
    margin-bottom: -1px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.selectBtn .options li {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.select_span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.content {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.content .search {
    position: relative;
}

.searchInput {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    height: 40px;
    border: 1px solid #E7E7E7;
    padding: 9px 10px;
}

.content .options {
    margin-top: 25px;
    max-height: 250px;
    overflow-y: auto;
}

.options::-webkit-scrollbar {
    width: 7px
}

.options::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #ccc;
}

.options li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-top: 1px;
    color: #000000;
    height: 50px;
    list-style-type: none;
    border-radius: 5px;
}

.text p {
    margin: 1px !important;
}
