.section_container {
    background-color: #ffffff;
    border-radius: 15px;
    border: 1px solid #d1d1d1;
    box-shadow: rgba(190, 196, 209, 0.4) 0px 2px 16px;
    padding: 12px 16px;
  }
  .section_flexContainer {
    display: flex;
    gap: 5px;
  }

  .bold {
    font-weight: bold;
  }

  .font_size_20 {
    font-size: 20px;
  }











.sticky_contact {
  position: sticky;
  top: 15px;
}

.bordered {
  border: 1px solid;
}

.sticky_column {
  position: sticky;
  top: 15px;
}

.padded_icon {
  margin-right: 0.25rem !important;
}

[dir="rtl"] .padded_icon {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.bg_white {
  background-color: #fff;
}

.headerPending {
  background-color: white;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0px 2px 16px 0px #bec4d166;
}

.bigCard {
  background-color: white;
  h2 {
    margin: 0;
  }
  p {
    color: #808083;
    margin: 0;
  }

  hr {
    border: 1px dashed #e7e7e7;
    height: 0px;
    width: 100%;
  }
  label {
    font-weight: 700;
    font-size: 1.1rem;
  }
  input {
    height: 50px;
    width: 100%;
    gap: 0px;
    border-radius: 8px;
    border: 1px solid #ededed;
  }
}

.verifyBtn {
  background-color: rgb(1, 121, 255);
  border: 2px solid rgb(1, 121, 255);
  color: white;
  font-size: 1rem;
  width: 50%;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

/* Listing container */
.listing_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.listing_title {
  font-weight: normal;
  font-size: 20px;
  color: #000000;
  margin: 5px 0;
}

.grid_container {
  display: grid;
  gap: 20px;
  grid-template-areas: "yyyIdentifier xxxIdentifier xxxIdentifier";
  grid-template-columns: 31.8333% 31.8333% 31.8333%;
}

.image_container {
  grid-area: yyyIdentifier;
  border-radius: 5px;
  padding: 0;
}

.image_content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.image_label_row {
  width: 100%;
}

.image_label_image {
  aspect-ratio: 1 / 1;
  width: 100%;
}

.text_container {
  grid-area: xxxIdentifier;
  border-radius: 5px;
  padding: 0;
}

.text_label {
  width: 100%;
  margin-top: 20px;
}

.text_label_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px;
}

.text_label_text {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  text-align: left;
  float: left;
  margin-top: 2px;
  cursor: default;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.4em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_label_text.normal {
  font-weight: normal;
}

.section_container {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  grid-area: contactUsSectionIdentifier;
  border-radius: 8px;
  box-shadow: rgba(190, 196, 209, 0.4) 0px 2px 16px;
  padding: 12px 16px;
}

.section_flexContainer {
  display: flex;
  gap: 5px;
}





.font_size_14 {
  font-size: 14px;
}

.color_black {
  color: #000000;
}

.color_gray {
  color: #747474;
}

.margin_5 {
  margin: 5px 0;
}

.section_button_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button_root {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  text-transform: unset;
  letter-spacing: 0;
}
.contactUsBtn {
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(209, 209, 209);
  text-transform: unset;
  letter-spacing: 0px;
  --font-weight: bold;
  --font-size: 18px;
  --font-color: #000000;
  direction: ltr !important;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
}

.button_outlined {
  border: 1px solid #d1d1d1;
}

.button_outlined_primary {
  border-color: #0056b3;
}

.button_size_medium {
  padding: 10px 20px;
}

.button_color_primary {
  background-color: #0056b3;
  color: #ffffff;
}

.d_block {
  display: block;
}

.button_body {
  width: 100%;
}

.button_text_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_icon {
  height: 25px;
  width: 25px;
}
