.textArea {
    --padding: 16px 14px;
    --border: 1px solid #ccc;
    --borderRadius: 8px;
    --text-font-size: 18px;
    --text-font-weight: normal;
    --text-font-color: #000;
    --transition: border-color 0.3s;
    --outline: none;
    --placeholder-font-size: 18px;
    --placeholder-font-weight: normal;
    --placeholder-font-color: #000;
    padding: var(--padding);
    border: var(--border);
    border-radius: var(--borderRadius);
    font-weight: var(--text-font-weight);
    font-size: var(--text-font-size);
    color:var(--text-font-color);
    transition: var(--transition);
    outline: var(--outline);
    width: 100%;
    resize: none;
    overflow: hidden;
}

.textArea::placeholder {
    font-weight: var(--placeholder-font-weight);
    /*font-size: var(--placeholder-font-size);*/
    color:var(--placeholder-font-color);
}
