.container{
    /* padding: 20px 20px; */
    width: 100%;
}
.map_container{
    width: 100%;
    min-height: 65vh;
}
.save{
    width: 100%;
    margin-top: 20px;
}

.button_container{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-radius: 15px;
    padding: 10px 20px;
}

@media (min-width: 1300px) {
    .button_container {
        left:auto;
        right: auto;
        bottom: 10px;
        width: 420px;
        padding: 0px 5px;

    }

    /*.footer{*/
    /*    width: 500px;*/
    /*}*/
}
