.container {

}

.tags_row {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    outline: 0!important;

}

.box {
    border: 2pt solid rgb(226, 225, 225);
    border-radius: 140px;
    padding: 0px 30px;
    text-align: center;
    font-weight: bolder;
}

.customButton {
    padding: 5px 20px !important;
    background: rgba(242, 242, 247, 1);
    border-radius: 140px;
    border: 0 !important;
    cursor: pointer;
    text-align: center;
    height: 38px !important;
    outline: 0!important;


}

.active{
    background-color: #0079ff;
    border: 0 !important;
    padding: 5px 20px !important;
    color: white !important;
    height: 38px !important;
}
/*.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
    color: #fff !important;
    background-color: #0079ff!important;
}*/

