*, ::before, ::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --container-width: 1180px
    /*--container-width: 500px*/

}


.cursor__pointer {
    cursor: pointer;
}


*{
  font-family: 'Vazirmatn', sans-serif !important
}

.grayBtn{
  background-color: gray !important;
  color : black !important;
}

.section{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    padding: 10px 20px;
}
.space{
    gap: 10px;
}
