.tags_row {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
}

.list_container {
    position: relative;
    padding: 2px 0px;
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: start !important;
    /*gap: 15px !important;*/
    /*overflow: hidden;*/
    gap:10px;
    height: 100%;
    outline: 0!important;
    div{white-space: nowrap}
}
.list_container_desktop,.list_container_mobile {
    /*button{width:12.5%}*/
    img{
        max-width: 100%;
        object-fit: cover;
    }
}
.list_container_desktop{
    button{width:15%}
    @media (min-width: 700px) and (max-width: 972px) {        /* Styles for large screens */
        button {
            width:20%
        }
    }
    @media (max-width: 699px) {        /* Styles for large screens */
        button {
            width:31%
        }
    }
}
.list_container_mobile{button{width:31%}}
.selected_item {
    border: 1px solid #0179FF !important;
}

.more_text {
    margin-top: 1px !important;
}

.margin_left {
    margin-left: 5px !important;
}

.unselected_item {
    border: 1px solid #EDEDED !important;
}

.item {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    height: 38px !important;
    padding: 5px;
    margin: 5px 10px !important;
}
.single_item {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    height: 38px !important;
    padding: 5px;
    /*margin: 5px 10px !important;*/
}

.dropDownMenuItems {
    border-bottom: 1px dashed #d9d9d969;
    border-radius: 10px;
    font-size: 20px;
    /*padding: 10px 10px;*/
    /*margin: 5px 0;*/
    width: 100%;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
}


.single_item_container {
    display: flex !important;
    margin: 5px 10px;
}

.single_item_icon_container {
    display: flex !important;
    margin: 5px 2px;
}

.list_all_items_container {
    max-height: 100% !important;
    /*height: 100% !important;*/
    /*overflow: unset !important;*/
}

.show_less_button {
    border-radius: 8px !important;
    color: #fff !important;
    background-color: #000000 !important;
    margin: 1px 0px !important;;
    height: 35px !important;
}


.show_more_button {
    border-radius: 8px !important;
    color: #fff !important;
    background-color: #000000 !important;
    margin: 1px 10px !important;;
    height: 35px !important;
}

.icon_dimensions {
    width: 25px !important;
    height: 25px !important;
}

.button_container {
    position: absolute;
    left:0;
    flex-direction: row;
    gap: 5px;
}

.customButton,.showMore {
    padding: 2px 0 10px !important;
    background: #fff;
    border-radius: 9px;
    border: 1px solid #EDEDED;
    cursor: pointer;
    text-align: center;
    height:auto !important;
    outline: 0!important;
    line-height:10px
}
.showMore {
    padding:10px 0!important;
}

.active {
    background-color: #0079ff;
    /*padding: 5px 20px !important;*/
    color: white !important;
    height: auto;
    outline: 0!important;
    font-weight: bold !important;
}

.customIconButton {
    padding: 5px 20px !important;
    background:#ffffff !important;
    border-radius: 15px;
    border: 1px solid #EDEDED !important;
    cursor: pointer;
    text-align: center;
    height: 80px !important;
    outline: 0!important;
    margin:5px
}

.activeIcon {
    background-color: #0079ff;
    border: 1px solid  #0079ff !important;
    padding: 5px 20px !important;
    color: #000000 !important;
    height: 80px !important;
}
.iconContainer{
    height: 40px !important;
    width: 50px;
    margin: auto !important;
}