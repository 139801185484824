.container {
    margin-top: 1em;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
}

.text {
    flex: 1;
    text-align: center;
    padding: 0 7px;
    text-transform: uppercase
}

.line {
    flex: 2;

}


