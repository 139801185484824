.container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-between;
    gap: 20px;
    padding: 0px 5px;

}

.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}
.icon_list_container{
    gap: 5px;
}
.icon_list_container li {
    font-weight: var(--text-font-weight);
    /*text-align: var(--text-font-align);*/
    font-size: var(--text-font-size);
    color: var(--text-font-color);
}
.icon_list_container ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}
.icon_list_container{
    gap: 5px;
}
.icon_list_container li {
    font-weight: normal;
    /*text-align: var(--text-font-align);*/
    font-size:14px;
    color: #000000;
}
.icon_list_container ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.header ul li .icon {
    font-size: 18px;
    color: #12bb00;
}

.customIcon {
    font-size: 18px;
    color: #12bb00;
}

.text {
    color: rgb(116, 116, 116);
    font-size: 15px;
}

.content {
    width: 100%;
}
@media only screen and (max-width: 1000px) {
    .content {
       width: 100%;
    }
}

.verticalAlign{
    vertical-align: middle !important
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
    padding-top: 10px;

}
.videoHeaderContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
}