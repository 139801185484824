/* Container to align input and label properly */
.checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Hide the default checkbox appearance */
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #007bff; /* Blue border */
    border-radius: 4px; /* Slight rounding like the browser default */
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.2s ease-in-out;
}

/* Checked state */
.custom-checkbox:checked {
    background-color: #007bff; /* Blue background */
    border-color: #007bff;
}

/* Create the checkmark (white tick) */
.custom-checkbox:checked::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Optional: Hover state */
.custom-checkbox:hover {
    border-color: #0056b3;
}

/* Optional: Focus outline for accessibility */
.custom-checkbox:focus {
    outline: 2px solid rgba(0, 123, 255, 0.5);
}
