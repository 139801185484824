.container {
    --text-font-weight: normal;
    --text-font-size: 18px;
    --text-font-color: #000;
    --text-font-align: center;
    margin-top: 1em;
}

.container div{
    font-weight: var(--text-font-weight);
    text-align: var(--text-font-align);
    font-size: var(--text-font-size);
    color: var(--text-font-color);
}


.float_right {
    float: right;
}