.button_container{
    /*background-color: white;*/
    position: fixed;
    bottom:0;
    left:0;
    width:100% ;
    /*height: 60px;*/
    display: flex;
    justify-content: center;
    z-index: 20;
}
.button_container_in_desktop{
    background-color: white;
    position: fixed;
    bottom:0;
    left:0;
    right: 0;
    width:100% !important;  ;
    /*height: 60px;*/
    box-shadow: 0px 4px 16px rgba(190, 196, 209, 0.5);
    display: flex;
    justify-content: center;
    z-index: 20;
}
.background_white{
    background-color: white;

}
.button_subcontainer_in_desktop{
    width: 1180px;
}

/*@media screen and (min-width: 1200px) {*/
/*    .button_container {*/
/*        left: auto !important;*/
/*        right: auto !important;*/
/*    }*/
/*}*/