.container .preview {
    width: 100%;
    background-color: rgb(242, 242, 246);
    padding: 10px;
}

.container {
    background-color: rgb(242, 242, 246);
    padding: 10px;
}

.box {
    background-color: white;
    border: 1pt solid white;
    border-radius: 5px;
    padding: 10px;
}

.title {
    font-weight: var(--title-font-weight);
    font-size: var(--title-font-size);
    color: var(--title-font-color);

}

.subtitle {
    font-weight: var(--subtitle-font-weight);
    font-size: var(--subtitle-font-size);
    color: var(--subtitle-font-color);

}

.icon {
    display: flex;
    justify-content: flex-end;

}
