.predictedPriceModal .modal-dialog {
  display: flex;
  justify-content: center;
}

/* Modal Header */
.predictedPriceModal .modal-content {
    display: flex;
    padding: 1rem 1rem 1rem;
    width:fit-content !important;
    border-radius: 8px !important;
}
.predictedPriceModal .modal-header,.modal-body,.modal-footer{
  padding:0 !important;
}
.predictedPriceModal .modal-header {
    border-bottom: none !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 15px !important;
    margin-bottom: 10px !important;
  }

.predictedPriceModal .modal-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px; 

  }

  
  /* Modal Body */
.predictedPriceModal .modal-body {
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    color: #000000; /* Bootstrap muted text color */
  }
  
  /* Modal Footer */
.predictedPriceModal  .modal-footer {
    border-top: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: none;
    margin-top: 20px;
   
  }
.predictedPriceModal .modal-footer button{
    display: block;
    width: 100%;
    height:60px ;
    border: 1px solid #007bff;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 20px;
    margin:5px 0;
    font-weight: bold;
}
  
  /* Yes Button */
.predictedPriceModal .yes-button {
    background-color: #007bff;
    color: white;
  }

.predictedPriceModal .yes-button:hover {
    background-color: #0056b3;
  }
  
  /* No Button */
.predictedPriceModal .no-button {
  
    background-color: white;
    color: #007bff;
  }

.predictedPriceModal .no-button:hover {
    background-color: #f8f9fa;
  }


.predictedPriceModal .custom-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Optional for a circular hover effect */
  transition: background-color 0.2s ease;
  position: absolute;
  top: 10px;
  right: 10px;
}

.predictedPriceModal .custom-close-btn svg {
  width: 16px;
  height: 16px;
  fill: black;
}


  @media only screen and (max-width: 426px) {
    .predictedPriceModal .modal-dialog{
      width: 100% !important;
      margin-left:0 !important;
      margin-right:0 !important;


    }
    .predictedPriceModal .modal-content {
        width: 100% !important;
        border-radius: 20px !important;
        position:fixed !important;
        bottom: 0 !important;
        padding: 2rem 1rem 1rem;  
    }
    .predictedPriceModal .modal-header{
      text-align: inherit;
      align-items: flex-start !important;
      padding-top: unset !important;
      margin-bottom: unset !important;
    }
    .predictedPriceModal  .no-button {
      color: rgb(0,0,0,1)
    }
    .predictedPriceModal .modal-body{
      text-align: inherit;
      margin-bottom: 20px !important;
      margin-top: 10px !important;
      font-size: 16px;
    line-height: unset;



    }
    .predictedPriceModal .modal-footer{
      margin-top: unset;
    }
    .predictedPriceModal .modal-title{
      margin: 25px 0;
      font-size: 20px ;
    }
    br {
      display: none;
    }
    .predictedPriceModal .custom-close-btn {
      position: unset;
      width: 36px;
      height: 36px;

      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

  }
