.container {
    --edit-container-box-length: 32px
}

.container {
    position: absolute !important;
    right: 5%;
    top: 10%;
    background-color: #fff !important;
    border: 1px solid #000 !important;
    border-radius: 50% !important;
    width: var(--edit-container-box-length) !important;
    height: var(--edit-container-box-length) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color :black !important;
}
.separator{
    border-bottom: 1px solid #F2F2F2 ;
}
.icon_dimensions{
    height: 20px !important;
    width: 20px !important;
}
.icon_label{
    display: flex;
    column-gap: 10px;

}
.icon_color{
    color: #57585A !important; ;
}