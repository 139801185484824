.container {
    padding: 5px;

}

.container.preview {
    width: 100%;
}


.image_text {
    display: flex;
    gap: 7px;
}

.image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    width: auto;
}

.text p {
    opacity: 0.75;
    margin-top: 5px;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}

.items_container {
    /*padding-top: 10px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 10px;*/
    /*padding: 5px;*/
    padding-top:8px;
}

div.items_container div:last-child {
    border-bottom: none !important;
}

.item {
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
    /*cursor: pointer;*/
    /*transition: .3s;*/
    /*border-radius: 11px;*/
    /*max-width:475px;*/
    /*padding: 1px;*/
    cursor: pointer;
    transition: .3s;
    border-radius: 11px;
    width: 100%;
    border-bottom: 1px dashed #EBEBEB;
    padding: 8px 8px 4px 8px;

}

.item:hover {
    /* background: #f9f9f9; */
}

.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

.no_found_data {
    padding: 40px;
    text-align: center !important;
    justify-content: center;
}

.image {
    /*aspect-ratio: 1 / 1;*/
    aspect-ratio: auto;

}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1px;

}


