.container {
    /* padding: 20px calc(100% / 5); */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    min-height: 70px;
    /*margin: 5px;*/
    display: flex;
    justify-content: center;
    background-color: white;
}

.containerNafath {
    min-height: 60px;
    display: flex;
    justify-content: center;
    background-color: white;
}

.containerNafath .items {
    display: flex;
    justify-content: space-between;
    width: var(--container-width);
    margin: 0 auto;

}

.containerNafath .item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerNafath .item.phone {
    gap: 10px;
}

.container .items {
    display: flex;
    justify-content: space-between;
    width: var(--container-width);
    margin: 0 auto;

}
.phoneCloseIcon_container{
    display: flex;
    justify-content: end!important;
    align-items: center;
    gap: 20px;
}
.container .item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .item.phone {
    gap: 10px;
}
.sideMargin{
    margin: 0px 10px !important;
}





/*.navbar__container .navbar__item*/