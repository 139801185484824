
.container{
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
}
.button_container {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    padding: 0 !important;
    cursor: pointer;
    transition: transform 0.3s ease;

}
@media only screen and (max-width: 700px) {
    .button_container{
        height: 40px;
        width: 40px;
    }
}

.labelButtonContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
    width: 100%;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/

}
.button_container div:hover {
    transform: scale(1.1);
}
.rowButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
}

.gray{
    filter: grayscale(100%);
}

.button {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    padding:0 !important;
    border: 0;
    cursor: pointer;
    background-color: white;

}

.buttonInClassicSelected{
    border: 1px solid  #0179FF !important;
    background-color: #0179FF !important;
}

.emoji_container {
    width: 100%;
    height: 100%;

}
.labelContainerInClassic{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.selectedButton{
    background-color: #0179FF;
    border-radius: 10px;
}
.selectedText{
    color: white !important;
}

.emoji {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    transform: scale(1.2);

}
.legend_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 10px;
}