.container {
    /*min-width: 100% !important;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
    /*margin-top: 10px;*/
    width: 100%;
    margin: 5px 0px;
}
.mobileContainer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    background-color: white;
}

.text_field {
    border-radius: 50px !important;
}

