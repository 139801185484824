
.container {
    /*--background-color: #fff;*/
    /*--border-color: #fff;*/
    /*border-radius: 5px;*/
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
}

.container > h1 {
    margin-bottom: 10px;
    font-size: 25px;
}
.icon_dimensions {
    width: 25px !important;
    height: 25px !important;
}
.show_more_button {
    border-radius: 8px !important;
    color: #fff !important;
    background-color: #000000 !important;
    margin: 1px 5px !important;;
    height: 35px !important;
}


/*.container p {*/
/*    margin-bottom: 10px;*/
/*}*/
.icon{
    font-size: 18px;
    color: #12bb00;
}

.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
    gap: 5px;
}