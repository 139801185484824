.container {
    --text-font-weight: normal;
    --text-font-size: 18px;
    --text-font-color: #000;
    --text-font-align: center;
    line-height: 1;
}

.container p {
    /*font-weight: var(--text-font-weight);*/
    /*text-align: var(--text-font-align);*/
    /*font-size: var(--text-font-size);*/
    /*color: var(--text-font-color);*/
}

.verticalAlign {
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}
.icon_list_container{
    gap: 5px;
}
.icon_list_container li {
    font-weight: var(--text-font-weight);
    /*text-align: var(--text-font-align);*/
    font-size: var(--text-font-size);
    color: var(--text-font-color);
}
.icon_list_container ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-inline-start: 20px;
}

.text_overflow  {
    /* text-overflow: ellipsis !important; */
    display: -webkit-box !important;
    /* -webkit-line-clamp: 2 !important; */
    -webkit-box-orient: vertical !important;
    line-height: 1.25em !important;
    word-break: break-word !important;
    overflow: hidden !important;
}
