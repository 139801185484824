.button_container{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;

}
.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
}

.content::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.content::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;

}

/* Handle */
.content::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #c8c8ce;

}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover {
    background-color: #88898c;
}

.overflowHidden {
    overflow: hidden
}

.overflowVisible {
    overflow: visible
}

.overflowHidden-x, .ssr {
    overflow-x: hidden
}

.model {
    background: #ffffff;
    /*height: 90vh;*/
    padding: 30px 30px 10px 30px;
    border: 1px solid #ddd7d7;
    border-radius: 15px;
    width: fit-content;
    position: relative;
    z-index: 300 !important;

}
.button{
    width: 150px;

}
.cancel_button{
    width: 150px;

}
.header {
   text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
}
.title{
    font-weight: bold;
    font-size: 18px;
}

.content {
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 20px 80px;
}


/*@media(min-width: 1301px){*/
/*    .model {*/
/*        width: 500px;*/
/*        padding: 30px;*/
/*    }*/
/*    .footer{*/
/*        width: 500px;*/

/*    }*/

/*}*/


/*@media (max-width: 1300px) {*/
/*    .model {*/
/*        width: 45vw;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 500px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 1000px) {*/
/*    .model {*/
/*        width: 50vw;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 400px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 800px) {*/
/*    .model {*/
/*        width: 70vw;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 400px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 700px) {*/
/*    .model {*/
/*        !*width: 80vw;*!*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 200px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 600px) {*/
/*    .model {*/
/*        !*width: 85vw;*!*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 200px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 500px) {*/
/*    .model {*/
/*        !*width: 95vw;*!*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        padding: 30px;*/
/*    }*/

/*    !*.footer{*!*/
/*    !*    width: 200px;*!*/
/*    !*}*!*/
/*}*/

/*@media (max-width: 400px) {*/
/*    .model {*/
/*        !*width: 100vw;*!*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        padding:5px;*/
/*    }*/

/*    !*.footer {*!*/
/*    !*!*left: 11px;*!*!*/
/*    !*}*!*/

/*}*/

/*@media (max-width: 300px) {*/
/*    .model {*/
/*        !*width: 100vw;*!*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        padding: 5px;*/
/*    }*/

/*}*/

