.container {
    /*padding:0;*/
    /*border-radius: 15px;*/
    /*border: 1px solid #d9d9d969;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/

    border-radius: 8px;
    /*padding-top:10px;*/
    /*padding-bottom: 10px;*/
    /*height:50px !important; ;*/
    width: 50%;
    border: 1px solid #d9d9d969;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
}
.wrapper{
    width: 100%;
    /*margin-left: 0.25rem !important;*/
    /*margin-right: 0.25rem !important;*/
}
.select{
    height: 60px!important;
    font-size: 22px;
    display: flex;
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid #EDEDED;
    background: #ffff ;
    justify-content: space-between;
}
.selected_values p{
    margin:0px !important;
}
.selectBtn_click {
    margin-bottom: -1px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.selectBtn_UnClick {
    margin-bottom: -1px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.selectBtn .options {
    /*display: flex;*/
    /*cursor: pointer;*/
    /*align-items: center;*/
}
.options{
    margin:5px 0px !important;
}
.content {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    background: white;
    z-index: 10;
    position: absolute;
    width: 50%;
    /*padding: 20px 10px 0 10px;*/
    border: 1px solid #EDEDED;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content .search {
    position: relative;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #57585A;

}

.separator {
    /*background-image: linear-gradient(to right, white 50%, #EBEBEB 0%);*/
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
    height: 1px;
}

.content .options {
    max-height: 400px;
    overflow-y: auto;
}

.options::-webkit-scrollbar {
    width: 7px
}

.options::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #ccc;
}
.list{
    padding: 10px;
}
.container.preview {
    width: 100%;
}
.image_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    margin: 5px;
}

.text p {
    opacity: 0.75;
    /*margin-top: 5px;*/
    margin: 0px !important;
}
.text h1 {
    margin: 0px !important;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}

.items_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.item {
    padding: 5px 30px 0px 15px;
    cursor: pointer;
    transition: .3s;
    border-radius: 8px;

}

.text_overflow  {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.25em;
    word-break: break-word;
    overflow: hidden;
}

.item:hover {
    /* background: #f9f9f9; */
}

.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

.no_found_data {
    padding: 40px;
    text-align: center !important;
    justify-content: center;
}
.disabled{
    background: #f9f9f9;
    cursor: auto ;

}

