.item {
    border: 1px solid #d9d9d969;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 10px;
    margin: 5px 0px !important;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}


.body {
    width: 100%;
}





