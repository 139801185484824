.button_body {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    --font-weight: normal;
    --font-size: 18px;
    --font-color: black
}

.text_icon_container {
    display: flex !important;
    gap: 5px;
    align-items: center !important;
}

.text_icon_container span {
    direction: ltr !important;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: var(--font-color);
    margin-top: 5px;
}

.text_icon_container div {
    direction: ltr !important;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: var(--font-color);
    margin-top: 5px;
}






