.container {
    --text-font-weight: normal;
    --text-font-size: 18px;
    --text-font-color: #000;
    --text-font-align: center;
    padding: 5px;
}


.verticalAlign{
    vertical-align: middle;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    vertical-align: middle;
}
.icon_list_container{
    gap: 5px;
}
.icon_list_container li {
    font-weight: var(--text-font-weight);
    /*text-align: var(--text-font-align);*/
    font-size: var(--text-font-size);
    color: var(--text-font-color);
}
.icon_list_container ul {
   display: flex;
    flex-direction: column;
    gap: 5px;
}