.container {
   padding: 5px;
}
.box {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    border: 1pt solid rgb(245, 244, 244);
}
div.items_container div:last-child{
    border-bottom: none !important;
}

.btn_header h1 {
    padding: 5px;
    margin-top: 5px;
    font-size: 25px;

}
.image_text {
    display: flex;
    gap: 7px;
}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    margin: 5px;
}

.text p {
    opacity: 0.75;
    margin-top: 5px;
}

.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 5px;
}

.items_container {
    /*padding-top: 10px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 15px;*/
    /*padding: 5px;*/
}

.item {
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/
    /*padding: 10px;*/
    /*cursor: pointer;*/
    /*transition: .3s;*/
    /*border-radius: 11px;*/
    cursor: pointer;
    transition: .3s;
    border-radius: 11px;
    width: 100%;
    border-bottom: 1px dashed #EBEBEB;
    padding: 4px 8px;

}


.search_field {
    border-radius: 8px;
    margin-bottom: 8px;
}

.no_found_data {
    padding: 40px;
    text-align: center !important;
}







