.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    top: 30vh;
}