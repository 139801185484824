.container {
    --media-box-size: 150px;
    --media-boxes-gap: 10px;
    --upload-media-box-border-radius: 8px;
}

.container {
    margin-bottom: 15px;
    margin-top: 25px;
    position: relative;
    /*direction: ltr !important;*/

}

/*.container * {*/
/*    direction: ltr !important;*/
/*}*/


.iframe_style {
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
}

.tip_container {
    font-size: 18px;
    color: #0179FF;
    gap: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.box_container {
    position: relative;
}


.upload-file {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
}

.parent_container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.error_btn {
    position: relative;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    transition: 0.2s all;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    background-color: #0179ff;
    height: 50px;
    width: 350px;

}

.float_right {
    float: right;
}

.float_left {
    float: left;
}

.error_btn:hover {
    box-shadow: 3px 2px 22px 1px rgba(98, 97, 97, 0.24);
}

.error_btn:active {
    background-color: #2b89f3;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 8px;
    color: #0179ff;
}


.text_container {
    background-color: #EBF4FF;
    border: 1px dashed #0179FF;
    border-radius: 8px;
}

.icon_list_container {
    gap: 15px;
    padding: 15px;

}

.custome_margin {
    margin-top: 60px;
}

.box {
    height: var(--media-box-size);
    width: var(--media-box-size);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    border-radius: var(--upload-media-box-border-radius);
    /*box-shadow: 0 4px 40px rgba(43, 89, 255, 0.1);*/
    /*padding: 0 15px;*/
    position: relative;
    overflow: hidden;
    border: 1px dashed #8F8E8E;
}

.medium .box {
    gap: 5px;
}

.last_box {
    background-color: #EBF4FF !important;
    border-color: #0179FF !important;
}

.normal_text {
    font-weight: normal !important;
    font-size: 14px;
}

.medium .normal_text {
    font-size: 10px;
}

.box_gap {
    row-gap: var(--media-boxes-gap);
    column-gap: var(--media-boxes-gap);
    padding: 0 !important;
    /*justify-content: center;*/
    align-items: center;
}

.customImageWidth {
    max-height: 170px;
}

.box.main {
    background: #0179FF;
    color: #fff;

}

.box .icon {
    max-width: 50px;
    margin: auto;
}

.box .icon .image-fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.medium .box .icon {
    max-width: 35px;

}

.box .icon img {
    max-width: 100%;
    height: 100%;
}


.box .text {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.medium .box .text {
    font-size: 13px;
}

.box.loaded {
    position: relative;
    background: #fff;
    overflow: hidden;
}


.box.loaded .icon {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box.loaded .icon img {
    max-width: unset !important;
    width: auto;
}


.box.loaded .text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 7px 0;
    background: #0179FF;
    border-radius: 0 0 var(--upload-media-box-border-radius) var(--upload-media-box-border-radius);
}

/*.box .edit__container {*/
/*    --edit-container-box-length: 32px*/
/*}*/

/*.box .edit__container {*/
/*    position: absolute;*/
/*    right: 5%;*/
/*    top: 10%;*/
/*    background-color: #fff;*/
/*    border-radius: 50%;*/
/*    width: var(--edit-container-box-length);*/
/*    height: var(--edit-container-box-length);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color :black*/
/*}*/


.progress-container {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--upload-media-box-border-radius);
    background-color: rgba(63, 63, 63, 0.6);
    width: 100%;
    height: 100%;
}

.progress-container .content {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
}

.progress-container .progress {
    width: 80%;
    position: absolute;
    top: 80%;
    border-radius: 7px;
    height: 5px;
}

.text-center {
    text-align: center !important;
}

.MuiButtonBase-root {
    text-align: center !important;
}

.count {
    width: 25px !important;
    height: 25px !important;
    background: #393939;
    color: #fff;
    border-radius: 50%;
    position: absolute !important;
    left: 6%;
    top: 10%;
    line-height: 28px !important;
    text-align: center;
    font-size: 14px;
    border: 1px solid #fff;
}

.cover_image {
    position: absolute !important;
    top: -35px;
    background-color: black;
    height: 50px;
    border-radius: 10px;
    color: white;
    text-align: center;
    padding-top: 6px;
    font-weight: bold;
    width: 100%;
}

.medium .cover_image {
    height: 40px;
    font-size: 12px;
    top: -28px;
}

@media only screen and (min-width: 1000px) {
    .container {
        /* display: grid;
        grid-template-columns: 200px 200px 200px;
        gap: 15px; */
        
    }
}

.mt-1 {
    margin-top: 10px;
}


.dragging__container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
}

.dragging__icon {
    font-size: 100px;
    color: #efefefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dragging__text {
    font-size: 23px;
    color: #efefefef;
    text-align: center;
    font-weight: bold;
}

.dragging__box {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 11px;
}


.solid_border {
    border: 1px solid #8F8E8E !important;
}

.z_index {
    z-index: 1;
}