.content-container-with-side-bar {
    margin: 10px auto;
    position: relative;
}

.content-container-with-side-bar {
    max-width: var(--container-width);
    padding: 0px 15px;
}
.content-container-with-side-bar-column-flex{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.section{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px 10px;
    gap: 5px;
}


.button_style{
    color: black !important;
    font-weight: bold !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 8px !important;
    height: 45px;
    text-transform: none !important;

}
.publish_button_style{
    color: white !important;
    font-weight: bold !important;
    border: 1px solid #D1D1D1 !important;
    background-color: #0179FF;
    border-radius: 8px !important;
    height: 60px;
    width: 300px;
    text-transform: none !important;

}
/*@media(min-width: 1301px){*/
/*    .content-container {*/
/*        width: 40%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/


/*@media(max-width: 1300px){*/
/*    .content-container {*/
/*        width: 50%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/

/*@media(max-width: 1000px){*/
/*    .content-container {*/
/*        width: 70%;*/
/*        padding: 20px;*/
/*    }*/
/*}*/


/*@media(max-width: 700px){*/
/*    .content-container {*/
/*        width: unset;*/
/*        padding: 30px;*/
/*    }*/
/*}*/