.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    /*margin-bottom: 40px;*/
    /*margin-top: 30px;*/
}


.header h1 {
    font-size: 25px;
    padding: 0;
    margin: 0;
}

.header ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header ul li {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header ul li:not(:last-child) {
    margin-bottom: 12px;
}

.header ul li .icon {
    font-size: 18px;
    color: #12bb00;
}



.content {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .content {
       width: 100%;
    }
}

.label {
  background-color: #fffaf4;
  border: 1px dotted #ffd392;
  height: 36px;
  /* width: 348px; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;

}
.img_icon{
  margin-left: 5px ;
  margin-right: 5px;
}