.container {
    /*padding: 20px 15px;*/
    display: flex;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 2px;
    direction: ltr;

}

.parent_container_in_desktop {
    border: 1px solid #D1D1D6 !important;

}

.parent_container_in_mobile {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}


.parent_container {
    border-radius: 10px !important;
    padding: 2px;
    width: 100%;
    background-color: white;

}

.line {
    margin-top: -2px;
    border-bottom: 1px solid #D1D1D6 !important;
}

.country_container {
    width: 100px;
    display: flex;
    gap: 0;
    border: 1px solid #ced4da;
    align-items: center;
    padding: 5px;
}

.custom-flex {
    display: flex;
    align-items: center;
}

.border_0 {
    border: 0 !important;
}

.img_label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border-right: 2px solid #D1D1D6;
    padding: 2px 6px;

}

.flag {
    display: flex;
    flex-wrap: nowrap;
    margin-top: -10px;
    margin-bottom: -5px;
}

.image {
    width: 100%;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.item {
    width: 28px;
    height: 30px;
}

.switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: 5px;
    margin-left: -15px;


}

/* Chrome, Safari, Edge, Opera */
.border_0 input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
.border_0 input[type=number] {
    -moz-appearance: textfield;
}

.parent_container input:focus {
    outline: none;
}