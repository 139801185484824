body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* height: 100%; */
    width: 100%;
    /* position: fixed; */
    /* overflow-y: scroll; */
}
html {
    /* overflow: hidden; */
    width: 100%;
}

body::-webkit-scrollbar {
    width: 6px;
}

/* Track */
body::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 20px;
    margin-top: 5px;


}

/* Handle */
body::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #c8c8ce;

}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background-color: #88898c;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
