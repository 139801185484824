.preview_step {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}
.title{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}