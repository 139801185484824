.container {
    border-radius: 15px;
    width: 100%;
    /*padding-top:10px;*/
    /*padding-bottom: 10px;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);*/

}

.mobileContainer {
    border-radius: 15px;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

}

.image {
    width: 100%;
}

.item {
    width: 25px;
    margin: 0 3px;

}

.body {
    /*padding: 10px 15px 0px 15px;*/
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.min, .max {
    color: #00000069;
    text-align: center;
    width: calc(100% / 2 - 5px);
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;

}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #E6E3E3;
    border-radius: 8px;
    padding: 10px;
    min-width: 100px;
    text-align: center;
}






