.sticky_column {
    position: sticky;
    top: 15px;
  }
  
  .padded_icon {
    margin-right: 0.25rem !important;
  }
  
  [dir="rtl"] .padded_icon {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  
  .bg_white {
    background-color: #fff;
  }
  
  .headerPending {
    background-color: white;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 8px;
    box-shadow: 0px 2px 16px 0px #bec4d166;
  }
  
  .bigCard {
    background-color: white;
    h2 {
      margin: 0;
    }
    p {
      color: #808083;
      margin: 0;
    }
  
    hr {
      border: 1px dashed #e7e7e7;
      height: 0px;
      width: 100%;
    }
    label {
      font-weight: 700;
      font-size: 1.1rem;
    }
    input {
      height: 50px;
      width: 100%;
      gap: 0px;
      border-radius: 8px;
      border: 1px solid #ededed;
    }
  }
  
  .verifyBtnMob {
    background-color: rgb(1, 121, 255);
    border: 2px solid rgb(1, 121, 255);
    color: white;
    font-size: 1rem;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }
  
  .verifyBtnDesk {
    background-color: rgb(1, 121, 255);
    border: 2px solid rgb(1, 121, 255);
    color: white;
    font-size: 1rem;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    width: 50%;
  }
  
  /* Listing container */
  .listing_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .verifyBtnCont {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    background-color: white;
  }
  
  /* Section container */
  .section_container {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    box-shadow: rgba(190, 196, 209, 0.4) 0px 2px 16px;
    padding: 12px 16px;
  }
  
  /* Listing title */
  .listing_title {
    font-weight: normal;
    font-size: 20px;
    color: #000000;
    margin: 5px 0;
  }
  
  /* Grid container */
  .grid_container {
    display: flex;
    gap: 20px;
  }
  
  /* Image container */
  .image_container {
    border-radius: 5px;
    padding: 0;
  }
  
  /* Image content */
  .image_content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  /* Image label row */
  .image_label_row {
    width: 33%;
  }
  
  /* Image */
  .image_label_image {
    aspect-ratio: 1.5 / 1;
    width: 100%;
    max-height: auto;
    border-radius: 8px;
  }
  
  /* Text container */
  .text_container {
    border-radius: 5px;
    padding: 0;
  }
  
  /* Text label */
  .text_label {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .text_label_postId {
    font-size: 0.9rem;
  }
  
  /* Text label content */
  .text_label_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1px;
  }
  
  /* Text label text */
  .text_label_text {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    text-align: left;
    float: left;
    margin-top: 2px;
    cursor: default;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    line-height: 1.4em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  /* Normal text label (second one) */
  .text_label_text.normal {
    font-weight: normal;
  }
  
  /* Section container */
  .section_container {
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    box-shadow: rgba(190, 196, 209, 0.4) 0px 2px 16px;
    padding: 12px 16px;
  }
  /* Flex container */
  .section_flexContainer {
    display: flex;
    gap: 5px;
  }
  
  /* Text container with bold font */
  
  .bold {
    font-weight: bold;
  }
  
  .font_size_20 {
    font-size: 20px;
  }
  
  .font_size_14 {
    font-size: 14px;
  }
  
  .color_black {
    color: #000000;
  }
  
  .color_gray {
    color: #747474;
  }
  
  .margin_5 {
    margin: 5px 0;
  }
  
  /* Button container */
  .section_button_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  /* Button styling */
  .button_root {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    text-transform: unset;
    letter-spacing: 0;
  }
  .contactUsBtn {
    font-weight: bold;
    font-size: 18px;
    color: rgb(0, 0, 0);
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(209, 209, 209);
    text-transform: unset;
    letter-spacing: 0px;
    --font-weight: bold;
    --font-size: 18px;
    --font-color: #000000;
    direction: ltr !important;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    margin-top: 5px;
    width: 100%;
  }
  
  .button_outlined {
    border: 1px solid #d1d1d1;
  }
  
  .button_outlined_primary {
    border-color: #0056b3;
  }
  
  .button_size_medium {
    padding: 10px 20px;
  }
  
  .button_color_primary {
    background-color: #0056b3;
    color: #ffffff;
  }
  
  .d_block {
    display: block;
  }
  
  .button_body {
    width: 100%;
  }
  
  /* Button text and icon container */
  .button_text_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Button icon */
  .button_icon {
    height: 25px;
    width: 25px;
  }
  